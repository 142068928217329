import React from 'react'
/* import { getCategorySlug, slugFormat } from '../../utilities'
import { graphql } from 'gatsby' */

/* const getUrl = (category, title, lang) => {
  return `${lang === 'en' ? '/en/our-works/' : '/nase-prace/'}${getCategorySlug(category, lang)}/${slugFormat(title)}`
}
 */
function applicationsSk({ data }) {
  return <div>a</div>
/*
  // const lang = 'sk' //TODO

  // const csArr = keys.map(key => {
  //   const pages = data[key].childMarkdownRemark.frontmatter
  //
  //   return {
  //     key: key,
  //     data: pages,
  //   }
  // "case_studies": [],
  //   "other_projects": [
  // })

  const urls = []

  const applicationsSk = data.applicationsSk.childMarkdownRemark.frontmatter

  // urls.push(...applicationsSk.case_studies.map(cs => getUrl(cs.category, cs.case_study, 'sk')))
  urls.push(...applicationsSk.other_projects.map(cs => getUrl(cs.category, cs.case_study, 'sk')))
  const graphicSk = data.graphicSk.childMarkdownRemark.frontmatter

  // urls.push(...graphicSk.logo_Branding.case_studies.map(cs => getUrl('logo_Branding', cs.case_study, 'sk')))
  urls.push(...graphicSk.logo_branding.other_projects.map(cs => getUrl('logo_branding', cs.case_study, 'sk')))

  // urls.push(...graphicSk.digital_print.case_studies.map(cs => getUrl('digital_print', cs.case_study, 'sk')))
  urls.push(...graphicSk.digital_print.other_projects.map(cs => getUrl('digital_print', cs.case_study, 'sk')))

  // const featured_referencesSk = data.featured_referencesSk.childMarkdownRemark.frontmatter

  // urls.push(...featured_referencesSk.case_studies.map(cs => getUrl(cs.category, cs.case_study, 'sk')))

  const web_pagesSk = data.web_pagesSk.childMarkdownRemark.frontmatter

  // urls.push(...web_pagesSk.corporate.case_studies.map(cs => getUrl('corporate', cs.case_study, 'sk')))
  urls.push(...web_pagesSk.corporate.other_projects.map(cs => getUrl('corporate', cs.case_study, 'sk')))

  // urls.push(...web_pagesSk.eshop.case_studies.map(cs => getUrl('eshop', cs.case_study, 'sk')))
  urls.push(...web_pagesSk.eshop.other_projects.map(cs => getUrl('eshop', cs.case_study, 'sk')))


  // urls.push(...web_pagesSk.crm.case_studies.map(cs => getUrl('crm', cs.case_study, 'sk')))
  urls.push(...web_pagesSk.crm.other_projects.map(cs => getUrl('crm', cs.case_study, 'sk')))

  // urls.push(...web_pagesSk.microsite.case_studies.map(cs => getUrl('microsite', cs.case_study, 'sk')))
  urls.push(...web_pagesSk.microsite.other_projects.map(cs => getUrl('microsite', cs.case_study, 'sk')))

  // urls.push(...web_pagesSk.portal.case_studies.map(cs => getUrl('portal', cs.case_study, 'sk')))
  urls.push(...web_pagesSk.portal.other_projects.map(cs => getUrl('portal', cs.case_study, 'sk')))

  const csUrls = data.caseStudies.edges
    .filter(({ node }) => {
      return node.frontmatter.language !== 'en';
    })
    .map(({ node }) => {
    const lang = node.frontmatter.language
    const slug = `${lang === 'en' ? '/en/our-works/' : '/nase-prace/'}${node.frontmatter.category}/${slugFormat(node.frontmatter.title)}`
    return slug
  })

  const goodCount = urls.filter(url => csUrls.indexOf(url) !== -1).length
  const badCount = urls.length - goodCount
  const notAssignedCount = csUrls.filter((url) => urls.indexOf(url) === -1).length;

  return <div className={'container'}>
    <h1>References URL test</h1>
    <p>Testuje ci hodnota odkazu na case study v sekcii referencies odkazuje na existujucu stranku.</p>
    <p>Z <b>{urls.length}</b> url adries je spravne priradenych <b>{goodCount}</b> a nespravne priradenych <b>{badCount}</b>. Projektov na ktore neodkazuje ziadna url je <b>{notAssignedCount}</b>.</p>
    {urls.map((url) => {
      return (
        <div>
          {csUrls.indexOf(url) !== -1 ? '✔️' : '❌'}
          <a href={url}>{url}</a>
        </div>
      )
    })}
    <h2 className={'mt-5 mb-2'}>Zoznam projektov na ktore sa nikde neodkazuje</h2>
    {csUrls.filter((url) => urls.indexOf(url) === -1).map((url) => {
      return (
        <div>
          <span role="img" aria-label="Mark">❔</span>
          <a href={url}>{url}</a>
        </div>
      )
    })}

  </div>
*/
}

/*
export const query = graphql`{
  caseStudies: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(/case-studies/)/"}}) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          language
          category
          title
          description
          what_we_done {
            text
          }
        }
        html
      }
    }
  }
  applicationsSk: file(relativePath: {eq: "pages/sk/references/applications.md"}) {
    childMarkdownRemark {
      frontmatter {
        description
        case_studies {
          title
          description
          
          background_color
          category
          case_study
        }
        other_projects {
            title
            url_override
          category
          
          case_study
        }
      }
    }
  }
  graphicSk: file(relativePath: {eq: "pages/sk/references/graphic.md"}) {
    childMarkdownRemark {
      frontmatter {
        description
        logo_branding {
          case_studies {
            title
            description
            
            background_color
            category
            case_study
          }
          other_projects {
            title
            url_override
            category
            
            case_study
          }
        }
        digital_print {
          case_studies {
            title
            description
            
            background_color
            category
            case_study
          }
          other_projects {
            title
            url_override
            category
            
            case_study
          }
        }
      }
    }
  }
  featured_referencesSk: file(relativePath: {eq: "pages/sk/references/featured_references.md"}) {
    childMarkdownRemark {
      frontmatter {
        description
        case_studies {
          title
          description
          
          background_color
          category
          case_study
        }
      }
    }
  }
  web_pagesSk: file(relativePath: {eq: "pages/sk/references/web_pages.md"}) {
    childMarkdownRemark {
      frontmatter {
        description
        corporate {
          case_studies {
            title
            description
            
            background_color
            case_study
          }
          other_projects {
            title
            url_override
            
            case_study
          }
        }
        eshop {
          case_studies {
            title
            description
            
            background_color
            case_study
          }
          other_projects {
            title
            url_override
            
            case_study
          }
        }
        crm {
          case_studies {
            title
            description
            
            background_color
            case_study
          }
          other_projects {
            title
            url_override
            
            case_study
          }
        }
        microsite {
          case_studies {
            title
            description
            
            background_color
            case_study
          }
          other_projects {
            title
            url_override
            
            case_study
          }
        }
        portal {
          case_studies {
            title
            description
            
            background_color
            case_study
          }
          other_projects {
            title
            url_override
            
            case_study
          }
        }
      }
    }
  }
}
`
*/


export default applicationsSk
